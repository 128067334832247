export default [
  // {
  //   title: 'Products',
  //   route: 'apps-products-list',
  //   icon: 'GridIcon'
  // },
  // {
  //   title: 'Orders',
  //   route: 'apps-orders-list',
  //   icon: 'ShoppingCartIcon'
  // },
  // {
  //   title: 'Categories',
  //   route: 'apps-categories',
  //   icon: 'ListIcon',
  // },
  // {
  //   title: 'Sub Categories',
  //   route: 'apps-subcategories',
  //   icon: 'ListIcon',
  // },
  // {
  //   title: 'Occasions',
  //   route: 'apps-occasions',
  //   icon: 'ClipboardIcon',
  // },
  // {
  //   title: 'Banners',
  //   route: 'apps-banners',
  //   icon: 'TvIcon',
  // },
  // {
  //   title: 'Pickup Slots',
  //   route: 'apps-pickup',
  //   icon: 'ClipboardIcon',
  // },
  // {
  //   title: 'Promo Code',
  //   route: 'apps-promocode',
  //   icon: 'StarIcon',
  // },
  {
    title: 'Businesses',
    route: 'apps-business',
    icon: 'BoldIcon',
  },
  {
    title: 'Banners',
    route: 'apps-banner',
    icon: 'TvIcon',
  },
  
  {
    title: 'Categories',
    route: 'cat',
    icon: 'AlignJustifyIcon',
  },
  {
    title: 'Sub catgories',
    route: 'apps-subcategories',
    icon: 'ListIcon',
  },
  {
    title: 'Vendors',
    route: 'apps-all-vendors',
    icon: 'UsersIcon',
  },

  {
    title: 'Products',
    route: 'apps-all-products',
    icon: 'GridIcon',
  },
  {
    title: 'Orders',
    route: 'apps-all-orders',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Services',
    route: 'apps-services',
    icon: 'LayersIcon',
  },
  {
    title: 'Bookings',
    route: 'apps-bookings',
    icon: 'BookmarkIcon',
  },
  {
    title: 'Messages',
    route: 'apps-chat',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'Price Management',
    route: 'apps-price',
    icon: 'CreditCardIcon',
  },
  // {
  //   title: 'Seller Registration',
  //   route: null,
  //   icon: 'UserPlusIcon',
  //   action:'read',
  //   resourse:''
  // },
  // {
  //   title: 'Reports',
  //   route: 'apps-todo',
  //   icon: 'BarChart2Icon',
  // },
  // {
  //   title: 'Setting',
  //   route: '#',
  //   icon: 'SettingsIcon',
  // },
];
